<template>
  <div>
    <b-form class="auth-login-form mt-2">
      <b-form-group label="Email" label-for="login-email">
        <b-form-input id="login-email"
          v-model="forgotForm.email"
          name="login-email"
          placeholder="Email"
          :state="emailErrors.length > 0 ? false:null"
          @input="$v.forgotForm.email.$touch()"/>
        <small class="text-danger">{{ emailErrors[0] }}</small>
      </b-form-group>
      <div>
        <b-overlay
          no-wrap
          :show="overlayForm"
          opacity="0.1"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
          @hidden="_onHidden()"/>
        <b-button
          block
          ref="button"
          :disabled="overlayForm"
          variant="primary"
          @click.prevent="_forgotPassword()">
          <feather-icon
            icon="SendIcon"
            class="mr-50"/>
          <span class="align-middle">
            Restablecer Contraseña
          </span>
        </b-button>
      </div>
    </b-form>
    <p class="text-center mt-2">
      <b-link :to="{name:'login'}">
        <feather-icon icon="ChevronLeftIcon" />
        Volver al inicio de sesión
      </b-link>
    </p>
  </div>
</template>

<script>
  import {mapActions,mapState} from 'vuex'
  import {required,email} from 'vuelidate/lib/validators'
  import ToastificationContent from '@core/components/toastification/ToastificationContent'

  export default{
    name: 'ForgotPasswordForm',
    data() {
      return {
        overlayForm: false,
        forgotForm:{
          email: '',
        }
      }
    },
    validations: {
      forgotForm: {
        email: {
          required,
          email
        },
      }
    },
    computed: {
      ...mapState('password',['forgotPassword']),
      emailErrors () {
        const errors = []
        if (!this.$v.forgotForm.email.$dirty) return errors
        !this.$v.forgotForm.email.required && errors.push('El email es obligatorio')
        !this.$v.forgotForm.email.email && errors.push('El email debe ser válido')
        return errors
      },
    },
    methods: {
      ...mapActions('password',['sendForgotPassword']),
      async _forgotPassword() {
        this.$v.forgotForm.$touch()
        if (!this.$v.forgotForm.$invalid) {
          this.overlayForm = true
          await this.sendForgotPassword(this.forgotForm)
          .then(res =>{
            this.$router.push({path: '/'}).catch(err => {console.info(err.message)})
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Se ha enviado un correo para restablecer contraseña',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            })
          }).catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'No se pudo enviar el email para restablecer contraseña',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
          }).finally(() => {
            this.overlayForm = false
          });
        }
      },
      _onHidden() {
        this.$refs.button.focus()
      },
    }
  }
</script>